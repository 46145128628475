unit UPWACalc;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.ExtCtrls, WEBLib.WebCtrls;

type
  TCalculatorOp = (opAdd, opSubtract, opMultiply, opDivide, opNone);


  TForm1 = class(TForm)
    WebPanel1: TPanel;
    ResLabel: TLabel;
    WebPanel2: TPanel;
    WebGridPanel1: TGridPanel;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    WebButton5: TButton;
    WebButton6: TButton;
    WebButton7: TButton;
    WebButton8: TButton;
    WebButton9: TButton;
    WebButton10: TButton;
    WebButton11: TButton;
    WebButton12: TButton;
    WebButton13: TButton;
    WebButton14: TButton;
    WebButton15: TButton;
    WebButton16: TButton;
    WebButton17: TButton;
    WebButton18: TButton;
    WebButton19: TButton;
    WebButton20: TButton;
    WebBrowserControl1: TBrowserControl;
    procedure WebButton5Click(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure WebButton8Click(Sender: TObject);
    procedure WebButton12Click(Sender: TObject);
    procedure WebButton16Click(Sender: TObject);
    procedure WebButton19Click(Sender: TObject);
    procedure WebButton20Click(Sender: TObject);
    procedure WebButton17Click(Sender: TObject);
  public
    FPrevNum: Double;
    FCurrNum: Double;
    FHasDecimalPoint: Boolean;
    FText: string;
    FOp: TCalculatorOp;
    procedure DoPreCalc;
    procedure InitCalc;
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

{ TForm1 }

procedure TForm1.DoPreCalc;
begin
  if FOp <> opNone then
  begin
    case FOp of
      opAdd: FPrevNum := FPrevNum + FCurrNum;
      opSubtract: FPrevNum := FPrevNum - FCurrNum;
      opMultiply: FPrevNum := FPrevNum * FCurrNum;
      opDivide:
      begin
        if FCurrNum <> 0 then
          ResLabel.Caption := Format('%g',[FPrevNum / FCurrNum]);
      end;
    end;
    ResLabel.Caption := Format('%g',[FPrevNum]);
  end
  else
    FPrevNum := StrToFloat(ResLabel.Caption);

  FText := '';
  FCurrNum := 0;
  FHasDecimalPoint := False;
end;

procedure TForm1.InitCalc;
begin
  FPrevNum := 0;
  FCurrNum := 0;
  FText := '';
  FHasDecimalPoint := False;
  FOp := opNone;
  ResLabel.Caption := '0.0';
end;

procedure TForm1.WebButton12Click(Sender: TObject);
begin
  DoPreCalc;
  FOp := opAdd;
end;

procedure TForm1.WebButton16Click(Sender: TObject);
var
  f: single;
begin
  f:= StrToFloat(ResLabel.Caption);
  f := -f;
  ResLabel.Caption := Format('%g', [f]);
end;

procedure TForm1.WebButton17Click(Sender: TObject);
begin
  FCurrNum := FPrevNum * FCurrNum / 100;
  ResLabel.Caption := Format('%g', [FCurrNum]);
  FText := '';
end;

procedure TForm1.WebButton19Click(Sender: TObject);
begin
  if ((Sender as TButton).Caption = '.') and not FHasDecimalPoint then
  begin
    FText := FText + (Sender as TButton).Caption;
    FHasDecimalPoint := True;
    if FText = '.' then
      FText := '0.';
  end
  else if (Sender as TButton).Caption <> '.' then
  begin
    FText := FText + (Sender as TButton).Caption;
  end;

  ResLabel.Caption := FText;
  FCurrNum := StrToFloat(FText);
end;

procedure TForm1.WebButton1Click(Sender: TObject);
begin
  InitCalc;
end;

procedure TForm1.WebButton20Click(Sender: TObject);
begin
  case FOp of
    opAdd: ResLabel.Caption := Format('%g', [FPrevNum + FCurrNum]);
    opSubtract: ResLabel.Caption := Format('%g',[FPrevNum - FCurrNum]);
    opMultiply: ResLabel.Caption := Format('%g',[FPrevNum * FCurrNum]);
    opDivide:
    begin
      if FCurrNum <> 0 then
        ResLabel.Caption := Format('%g',[ FPrevNum / FCurrNum]);
    end;
  end;

  FText := '';
  FOp := opNone;
end;

procedure TForm1.WebButton2Click(Sender: TObject);
begin
  if FText <> '' then
  begin
    Delete(FText, Length(FText), 1);
    if (FText = '.') or (FText = '') then
      FText := '0.';
    ResLabel.Caption := FText;
  end
  else
    ResLabel.Caption := '0.0';
end;

procedure TForm1.WebButton3Click(Sender: TObject);
begin
  DoPreCalc;
  FOp := opDivide;
end;

procedure TForm1.WebButton4Click(Sender: TObject);
begin
  DoPreCalc;
  FOp := opMultiply;
end;

procedure TForm1.WebButton5Click(Sender: TObject);
begin
  if ((Sender as TButton).Caption = '.') and not FHasDecimalPoint then
  begin
    FText := FText + (Sender as TButton).Caption;
    FHasDecimalPoint := True;
    if FText = '.' then
      FText := '0.';
  end
  else if (Sender as TButton).Caption <> '.' then
  begin
    FText := FText + (Sender as TButton).Caption;
  end;

  ResLabel.Caption := FText;
  FCurrNum := StrToFloat(ResLabel.Caption);
end;

procedure TForm1.WebButton8Click(Sender: TObject);
begin
  DoPreCalc;
  FOp := opSubtract;
end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebPanel1 := TPanel.Create(Self);
  ResLabel := TLabel.Create(Self);
  WebPanel2 := TPanel.Create(Self);
  WebGridPanel1 := TGridPanel.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  WebButton3 := TButton.Create(Self);
  WebButton4 := TButton.Create(Self);
  WebButton5 := TButton.Create(Self);
  WebButton6 := TButton.Create(Self);
  WebButton7 := TButton.Create(Self);
  WebButton8 := TButton.Create(Self);
  WebButton9 := TButton.Create(Self);
  WebButton10 := TButton.Create(Self);
  WebButton11 := TButton.Create(Self);
  WebButton12 := TButton.Create(Self);
  WebButton13 := TButton.Create(Self);
  WebButton14 := TButton.Create(Self);
  WebButton15 := TButton.Create(Self);
  WebButton16 := TButton.Create(Self);
  WebButton17 := TButton.Create(Self);
  WebButton18 := TButton.Create(Self);
  WebButton19 := TButton.Create(Self);
  WebButton20 := TButton.Create(Self);
  WebBrowserControl1 := TBrowserControl.Create(Self);

  WebPanel1.BeforeLoadDFMValues;
  ResLabel.BeforeLoadDFMValues;
  WebPanel2.BeforeLoadDFMValues;
  WebGridPanel1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  WebButton5.BeforeLoadDFMValues;
  WebButton6.BeforeLoadDFMValues;
  WebButton7.BeforeLoadDFMValues;
  WebButton8.BeforeLoadDFMValues;
  WebButton9.BeforeLoadDFMValues;
  WebButton10.BeforeLoadDFMValues;
  WebButton11.BeforeLoadDFMValues;
  WebButton12.BeforeLoadDFMValues;
  WebButton13.BeforeLoadDFMValues;
  WebButton14.BeforeLoadDFMValues;
  WebButton15.BeforeLoadDFMValues;
  WebButton16.BeforeLoadDFMValues;
  WebButton17.BeforeLoadDFMValues;
  WebButton18.BeforeLoadDFMValues;
  WebButton19.BeforeLoadDFMValues;
  WebButton20.BeforeLoadDFMValues;
  WebBrowserControl1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 399;
    Height := 632;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 607;
    WebPanel1.Width := 399;
    WebPanel1.Height := 25;
    WebPanel1.ElementClassName := 'toppanel';
    WebPanel1.WidthStyle := ssPercent;
    WebPanel1.Align := alBottom;
    WebPanel1.Visible := False;
    ResLabel.SetParentComponent(WebPanel1);
    ResLabel.Name := 'ResLabel';
    ResLabel.Left := 0;
    ResLabel.Top := 0;
    ResLabel.Width := 399;
    ResLabel.Height := 25;
    ResLabel.Align := alClient;
    ResLabel.Alignment := taRightJustify;
    ResLabel.AutoSize := False;
    ResLabel.Caption := '0.0';
    ResLabel.Font.Charset := DEFAULT_CHARSET;
    ResLabel.Font.Color := clWindowText;
    ResLabel.Font.Height := -64;
    ResLabel.Font.Name := 'DitigalRegular';
    ResLabel.Font.Style := [];
    ResLabel.HeightPercent := 100.000000000000000000;
    ResLabel.Layout := tlCenter;
    ResLabel.ParentFont := False;
    ResLabel.WidthPercent := 100.000000000000000000;
    WebPanel2.SetParentComponent(Self);
    WebPanel2.Name := 'WebPanel2';
    WebPanel2.Left := 0;
    WebPanel2.Top := 482;
    WebPanel2.Width := 399;
    WebPanel2.Height := 125;
    WebPanel2.ElementClassName := 'toppanel';
    WebPanel2.WidthStyle := ssPercent;
    WebPanel2.Align := alBottom;
    WebPanel2.Visible := False;
    WebGridPanel1.SetParentComponent(WebPanel2);
    WebGridPanel1.Name := 'WebGridPanel1';
    WebGridPanel1.Left := 0;
    WebGridPanel1.Top := 0;
    WebGridPanel1.Width := 399;
    WebGridPanel1.Height := 125;
    WebGridPanel1.WidthStyle := ssPercent;
    WebGridPanel1.Align := alClient;
    WebGridPanel1.ColumnCollection.Clear;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 25;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 25;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 25;
    end;
    with WebGridPanel1.ColumnCollection.Add do
    begin
      Value := 25;
    end;
    WebGridPanel1.ControlCollection.Clear;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton1;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton2;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton3;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton4;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton5;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton6;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton7;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton8;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton9;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton10;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton11;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton12;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton13;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton14;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton15;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton16;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton17;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton18;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton19;
    end;
    with WebGridPanel1.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebButton20;
    end;
    WebGridPanel1.Color := clWhite;
    WebGridPanel1.GridLineColor := clBlack;
    WebGridPanel1.RowCollection.Clear;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 20;
    end;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 20;
    end;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 20;
    end;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 20;
    end;
    with WebGridPanel1.RowCollection.Add do
    begin
      Value := 20;
    end;
    WebButton1.SetParentComponent(WebGridPanel1);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 2;
    WebButton1.Top := 2;
    WebButton1.Width := 96;
    WebButton1.Height := 21;
    WebButton1.Align := alClient;
    WebButton1.Caption := 'AC';
    WebButton1.ElementClassName := 'keybutton';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(WebGridPanel1);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 102;
    WebButton2.Top := 2;
    WebButton2.Width := 96;
    WebButton2.Height := 21;
    WebButton2.Align := alClient;
    WebButton2.Caption := 'Del';
    WebButton2.ElementClassName := 'keybutton';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(WebGridPanel1);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 202;
    WebButton3.Top := 2;
    WebButton3.Width := 96;
    WebButton3.Height := 21;
    WebButton3.Align := alClient;
    WebButton3.Caption := #247;
    WebButton3.ElementClassName := 'keybutton';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(WebGridPanel1);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 302;
    WebButton4.Top := 2;
    WebButton4.Width := 96;
    WebButton4.Height := 21;
    WebButton4.Align := alClient;
    WebButton4.Caption := #215;
    WebButton4.ElementClassName := 'keybutton';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    WebButton5.SetParentComponent(WebGridPanel1);
    WebButton5.Name := 'WebButton5';
    WebButton5.Left := 2;
    WebButton5.Top := 27;
    WebButton5.Width := 96;
    WebButton5.Height := 21;
    WebButton5.Align := alClient;
    WebButton5.Caption := '7';
    WebButton5.ElementClassName := 'keybutton';
    WebButton5.ElementFont := efCSS;
    WebButton5.HeightPercent := 100.000000000000000000;
    WebButton5.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton5, Self, 'OnClick', 'WebButton5Click');
    WebButton6.SetParentComponent(WebGridPanel1);
    WebButton6.Name := 'WebButton6';
    WebButton6.Left := 102;
    WebButton6.Top := 27;
    WebButton6.Width := 96;
    WebButton6.Height := 21;
    WebButton6.Align := alClient;
    WebButton6.Caption := '8';
    WebButton6.ElementClassName := 'keybutton';
    WebButton6.ElementFont := efCSS;
    WebButton6.HeightPercent := 100.000000000000000000;
    WebButton6.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton6, Self, 'OnClick', 'WebButton5Click');
    WebButton7.SetParentComponent(WebGridPanel1);
    WebButton7.Name := 'WebButton7';
    WebButton7.Left := 202;
    WebButton7.Top := 27;
    WebButton7.Width := 96;
    WebButton7.Height := 21;
    WebButton7.Align := alClient;
    WebButton7.Caption := '9';
    WebButton7.ElementClassName := 'keybutton';
    WebButton7.ElementFont := efCSS;
    WebButton7.HeightPercent := 100.000000000000000000;
    WebButton7.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton7, Self, 'OnClick', 'WebButton5Click');
    WebButton8.SetParentComponent(WebGridPanel1);
    WebButton8.Name := 'WebButton8';
    WebButton8.Left := 302;
    WebButton8.Top := 27;
    WebButton8.Width := 96;
    WebButton8.Height := 21;
    WebButton8.Align := alClient;
    WebButton8.Caption := '-';
    WebButton8.ElementClassName := 'keybutton';
    WebButton8.ElementFont := efCSS;
    WebButton8.HeightPercent := 100.000000000000000000;
    WebButton8.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton8, Self, 'OnClick', 'WebButton8Click');
    WebButton9.SetParentComponent(WebGridPanel1);
    WebButton9.Name := 'WebButton9';
    WebButton9.Left := 2;
    WebButton9.Top := 52;
    WebButton9.Width := 96;
    WebButton9.Height := 21;
    WebButton9.Align := alClient;
    WebButton9.Caption := '4';
    WebButton9.ElementClassName := 'keybutton';
    WebButton9.ElementFont := efCSS;
    WebButton9.HeightPercent := 100.000000000000000000;
    WebButton9.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton9, Self, 'OnClick', 'WebButton5Click');
    WebButton10.SetParentComponent(WebGridPanel1);
    WebButton10.Name := 'WebButton10';
    WebButton10.Left := 102;
    WebButton10.Top := 52;
    WebButton10.Width := 96;
    WebButton10.Height := 21;
    WebButton10.Align := alClient;
    WebButton10.Caption := '5';
    WebButton10.ElementClassName := 'keybutton';
    WebButton10.ElementFont := efCSS;
    WebButton10.HeightPercent := 100.000000000000000000;
    WebButton10.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton10, Self, 'OnClick', 'WebButton5Click');
    WebButton11.SetParentComponent(WebGridPanel1);
    WebButton11.Name := 'WebButton11';
    WebButton11.Left := 202;
    WebButton11.Top := 52;
    WebButton11.Width := 96;
    WebButton11.Height := 21;
    WebButton11.Align := alClient;
    WebButton11.Caption := '6';
    WebButton11.ElementClassName := 'keybutton';
    WebButton11.ElementFont := efCSS;
    WebButton11.HeightPercent := 100.000000000000000000;
    WebButton11.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton11, Self, 'OnClick', 'WebButton5Click');
    WebButton12.SetParentComponent(WebGridPanel1);
    WebButton12.Name := 'WebButton12';
    WebButton12.Left := 302;
    WebButton12.Top := 52;
    WebButton12.Width := 96;
    WebButton12.Height := 21;
    WebButton12.Align := alClient;
    WebButton12.Caption := '+';
    WebButton12.ElementClassName := 'keybutton';
    WebButton12.ElementFont := efCSS;
    WebButton12.HeightPercent := 100.000000000000000000;
    WebButton12.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton12, Self, 'OnClick', 'WebButton12Click');
    WebButton13.SetParentComponent(WebGridPanel1);
    WebButton13.Name := 'WebButton13';
    WebButton13.Left := 2;
    WebButton13.Top := 77;
    WebButton13.Width := 96;
    WebButton13.Height := 21;
    WebButton13.Align := alClient;
    WebButton13.Caption := '1';
    WebButton13.ElementClassName := 'keybutton';
    WebButton13.ElementFont := efCSS;
    WebButton13.HeightPercent := 100.000000000000000000;
    WebButton13.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton13, Self, 'OnClick', 'WebButton5Click');
    WebButton14.SetParentComponent(WebGridPanel1);
    WebButton14.Name := 'WebButton14';
    WebButton14.Left := 102;
    WebButton14.Top := 77;
    WebButton14.Width := 96;
    WebButton14.Height := 21;
    WebButton14.Align := alClient;
    WebButton14.Caption := '2';
    WebButton14.ElementClassName := 'keybutton';
    WebButton14.ElementFont := efCSS;
    WebButton14.HeightPercent := 100.000000000000000000;
    WebButton14.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton14, Self, 'OnClick', 'WebButton5Click');
    WebButton15.SetParentComponent(WebGridPanel1);
    WebButton15.Name := 'WebButton15';
    WebButton15.Left := 202;
    WebButton15.Top := 77;
    WebButton15.Width := 96;
    WebButton15.Height := 21;
    WebButton15.Align := alClient;
    WebButton15.Caption := '3';
    WebButton15.ElementClassName := 'keybutton';
    WebButton15.ElementFont := efCSS;
    WebButton15.HeightPercent := 100.000000000000000000;
    WebButton15.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton15, Self, 'OnClick', 'WebButton5Click');
    WebButton16.SetParentComponent(WebGridPanel1);
    WebButton16.Name := 'WebButton16';
    WebButton16.Left := 302;
    WebButton16.Top := 77;
    WebButton16.Width := 96;
    WebButton16.Height := 21;
    WebButton16.Align := alClient;
    WebButton16.Caption := #177;
    WebButton16.ElementClassName := 'keybutton';
    WebButton16.ElementFont := efCSS;
    WebButton16.HeightPercent := 100.000000000000000000;
    WebButton16.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton16, Self, 'OnClick', 'WebButton16Click');
    WebButton17.SetParentComponent(WebGridPanel1);
    WebButton17.Name := 'WebButton17';
    WebButton17.Left := 2;
    WebButton17.Top := 102;
    WebButton17.Width := 96;
    WebButton17.Height := 21;
    WebButton17.Align := alClient;
    WebButton17.Caption := '%';
    WebButton17.ElementClassName := 'keybutton';
    WebButton17.ElementFont := efCSS;
    WebButton17.HeightPercent := 100.000000000000000000;
    WebButton17.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton17, Self, 'OnClick', 'WebButton17Click');
    WebButton18.SetParentComponent(WebGridPanel1);
    WebButton18.Name := 'WebButton18';
    WebButton18.Left := 102;
    WebButton18.Top := 102;
    WebButton18.Width := 96;
    WebButton18.Height := 21;
    WebButton18.Align := alClient;
    WebButton18.Caption := '0';
    WebButton18.ElementClassName := 'keybutton';
    WebButton18.ElementFont := efCSS;
    WebButton18.HeightPercent := 100.000000000000000000;
    WebButton18.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton18, Self, 'OnClick', 'WebButton5Click');
    WebButton19.SetParentComponent(WebGridPanel1);
    WebButton19.Name := 'WebButton19';
    WebButton19.Left := 202;
    WebButton19.Top := 102;
    WebButton19.Width := 96;
    WebButton19.Height := 21;
    WebButton19.Align := alClient;
    WebButton19.Caption := '.';
    WebButton19.ElementClassName := 'keybutton';
    WebButton19.ElementFont := efCSS;
    WebButton19.HeightPercent := 100.000000000000000000;
    WebButton19.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton19, Self, 'OnClick', 'WebButton19Click');
    WebButton20.SetParentComponent(WebGridPanel1);
    WebButton20.Name := 'WebButton20';
    WebButton20.Left := 302;
    WebButton20.Top := 102;
    WebButton20.Width := 96;
    WebButton20.Height := 21;
    WebButton20.Align := alClient;
    WebButton20.Caption := '=';
    WebButton20.ElementClassName := 'keybutton';
    WebButton20.ElementFont := efCSS;
    WebButton20.HeightPercent := 100.000000000000000000;
    WebButton20.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton20, Self, 'OnClick', 'WebButton20Click');
    WebBrowserControl1.SetParentComponent(Self);
    WebBrowserControl1.Name := 'WebBrowserControl1';
    WebBrowserControl1.Left := 0;
    WebBrowserControl1.Top := 0;
    WebBrowserControl1.Width := 399;
    WebBrowserControl1.Height := 482;
    WebBrowserControl1.Align := alClient;
    WebBrowserControl1.ChildOrder := 2;
    WebBrowserControl1.ReferrerPolicy := rfOrigin;
    WebBrowserControl1.Sandbox := [stAllowForms,stAllowModals,stAllowOrientationLock,stAllowPointerLock,stAllowPopups,stAllowPopupsToEscapeSandbox,stAllowPresentation,stAllowSameOrigin,stAllowScripts,stAllowTopNavigation,stAllowTopNavigationByUserActivation];
    WebBrowserControl1.URL := 'https://turko.biz';
  finally
    WebPanel1.AfterLoadDFMValues;
    ResLabel.AfterLoadDFMValues;
    WebPanel2.AfterLoadDFMValues;
    WebGridPanel1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    WebButton5.AfterLoadDFMValues;
    WebButton6.AfterLoadDFMValues;
    WebButton7.AfterLoadDFMValues;
    WebButton8.AfterLoadDFMValues;
    WebButton9.AfterLoadDFMValues;
    WebButton10.AfterLoadDFMValues;
    WebButton11.AfterLoadDFMValues;
    WebButton12.AfterLoadDFMValues;
    WebButton13.AfterLoadDFMValues;
    WebButton14.AfterLoadDFMValues;
    WebButton15.AfterLoadDFMValues;
    WebButton16.AfterLoadDFMValues;
    WebButton17.AfterLoadDFMValues;
    WebButton18.AfterLoadDFMValues;
    WebButton19.AfterLoadDFMValues;
    WebButton20.AfterLoadDFMValues;
    WebBrowserControl1.AfterLoadDFMValues;
  end;
end;

end.
